import { QUESTION_COMPONENTS, TYPES } from '@/constants/questionTypes';

export default {
    methods: {
        getKey (type) {
            return parseInt(type);
        },
        hasKey (type) {
            const key = this.getKey(type);

            return QUESTION_COMPONENTS.has(key);
        },
        configureComponent (item) {
            const key = item.autocomplete ? TYPES.AUTOCOMPLETE : this.getKey(item.question_type);

            const config = QUESTION_COMPONENTS.get(key);

            const attributes = {};
            config.attributes.forEach(option => {
                let value;

                switch (option) {
                case 'value':
                    value = item.answer;
                    break;
                case 'options':
                    if (item.question_parameters) {
                        value = JSON.parse(item.question_parameters);
                    }
                    break;
                case 'values':
                    if (item.answer) {
                        try {
                            value = JSON.parse(item.answer);
                        } catch (e) {
                            value = item.answer.split(',');
                        }
                    }
                    break;
                case 'pictures':
                    value = item.pictures;
                    break;
                case 'incorrect':
                    value = item.is_incorrect;
                    break;
                case 'label':
                    value = item.question;
                    break;
                }

                attributes[option] = value;
            });

            return {
                name: config.name,
                attributes
            };
        },
        getText (item) {
            return item.question_type === TYPES.QR_CODE ? item.name_for_code : item.answer;
        }
    }
};
