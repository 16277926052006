<template>
    <div v-if="(readonly && value) || (!readonly && !notApplicable)">
        <v-rating
            v-bind="$attrs"
            :length="length"
            :size="size"
            :readonly="readonly"
            :value="ratingValue"
            :background-color="color"
            :color="color"
            v-model="questionValue"
        />
        <ValidationProvider
            :name="label"
            :vid="id"
            :rules="required ? {required: true, min_value: 1} : {}"
            v-slot="{ errors }"
        >
            <input
                type="hidden"
                :name="id"
                :value="ratingValue"
            />
            <v-messages
                color="error"
                :value="errors"
            />
        </ValidationProvider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { VMessages } from 'vuetify/lib';

export default {
    name: 'Rating',
    components: { ValidationProvider, VMessages },
    data: () => ({
        questionValue: 0
    }),
    props: {
        id: {
            type: String
        },
        length: {
            type: [String, Number],
            'default': 5
        },
        size: {
            type: [String, Number],
            'default': 18
        },
        value: {
            type: [String, Number]
        },
        label: {
            type: String,
            'default': ''
        },
        incorrect: {
            type: Boolean,
            'default': false
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        },
        notApplicable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ratingValue () {
            return typeof this.questionValue === 'string' ? parseInt(this.questionValue || 0) : this.questionValue;
        },
        color () {
            return this.incorrect ? 'red' : 'primary';
        }
    },
    created () {
        this.$root.$on('autocomplete', () => {
            this.questionValue = +this.value;
        });

        this.questionValue = +this.value;
    },
    watch: {
        value (val) {
            this.questionValue = +val;
        }
    }
};
</script>
