<template>
    <span v-if="readonly">{{ questionValue }}</span>
    <TextField
        v-else-if="!notApplicable"
        :label="label"
        :disabled="readonly"
        :name="id"
        v-model="questionValue"
        :rules="required ? {required: true} : {}"
    />
</template>

<script>
import TextField from '@/components/widgets/forms/TextField';
export default {
    name: 'QuestionText',
    components: { TextField },
    data: () => ({
        questionValue: ''
    }),
    props: {
        id: {
            type: String
        },
        value: {
            type: String,
            'default': ''
        },
        label: {
            type: String,
            'default': ''
        },
        readonly: {
            type: Boolean,
            'default': true
        },
        required: {
            type: Boolean,
            'default': false
        },
        notApplicable: {
            type: Boolean,
            'default': false
        }
    },
    created () {
        this.$root.$on('autocomplete', () => {
            this.questionValue = this.value;
        });

        this.questionValue = this.value;
    },
    watch: {
        value (val) {
            this.questionValue = val;
        }
    }
};
</script>
